<template>
    <v-layout wrap>
        <v-flex xs12 class="d-flex justify-space-between">
            <span class="display-1 px-5 pb-5 font-weight-medium"
                >Utilizatori</span
            >
            <v-btn
                @click.stop="userCreateDialog = true"
                color="secondary"
                v-if="hasPermission"
                >Utilizator Nou</v-btn
            >
        </v-flex>
        <v-flex xs12 ml-5>
            <v-data-table
                :items="data.items"
                :options.sync="data.pagination"
                :server-items-length="data.total"
                @update:options="getData"
                :loading="loading"
                :footer-props="{
                    'items-per-page-options': $vuetifyTableItemsPerPage
                }"
                :headers="headers"
            >
                <template v-slot:no-data>
                    <v-alert type="info">Nici un rezultat.</v-alert>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-flex v-if="hasPermission">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="prepareForUpdate(item)"
                                    color="primary"
                                    icon
                                    v-on="on"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editează Utilizatorul {{ item.name }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="prepareForUpdatePassword(item)"
                                    color="warning"
                                    icon
                                    v-on="on"
                                >
                                    <v-icon>mdi-lock-open</v-icon>
                                </v-btn>
                            </template>
                            <span
                                >Schimbă Parola Utilizatorului
                                {{ item.name }}</span
                            >
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="deleteUser(item)"
                                    color="error"
                                    icon
                                    v-on="on"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Sterge Utilizatorul {{ item.name }}</span>
                        </v-tooltip>
                    </v-flex>
                </template>
            </v-data-table>
        </v-flex>
        <v-dialog
            @keydown.esc="userCreateDialog = false"
            max-width="900px"
            persistent
            v-model="userCreateDialog"
        >
            <v-card>
                <v-card-title
                    class="headline primary white--text"
                    primary-title
                >
                    {{ createDialogTitle }}
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex v-if="!editPassword" xs12>
                                <v-text-field
                                    :error-messages="errors.name"
                                    @input="errors.name = []"
                                    label="Nume"
                                    prepend-icon="mdi-account"
                                    v-model="newUser.name"
                                ></v-text-field>
                            </v-flex>
                            <v-flex v-if="!editPassword" xs12>
                                <v-text-field
                                    :error-messages="errors.email"
                                    @input="errors.email = []"
                                    label="Email"
                                    prepend-icon="mdi-email"
                                    v-model="newUser.email"
                                ></v-text-field>
                            </v-flex>
                            <v-flex v-if="!editMode" xs12>
                                <v-text-field
                                    :append-icon="
                                        showPassword ? 'mdi-eye-off' : 'mdi-eye'
                                    "
                                    :error-messages="errors.password"
                                    :type="showPassword ? 'text' : 'password'"
                                    @click:append="showPassword = !showPassword"
                                    @input="errors.password = []"
                                    counter
                                    hint="Cel puțin 6 caractere"
                                    label="Parola"
                                    prepend-icon="mdi-lock"
                                    v-model="newUser.password"
                                ></v-text-field>
                            </v-flex>
                            <v-flex v-if="!editMode" xs12>
                                <v-text-field
                                    :append-icon="
                                        showPasswordConfirm
                                            ? 'mdi-eye-off'
                                            : 'mdi-eye'
                                    "
                                    :error-messages="errors.password"
                                    :type="
                                        showPasswordConfirm
                                            ? 'text'
                                            : 'password'
                                    "
                                    @click:append="
                                        showPasswordConfirm = !showPasswordConfirm
                                    "
                                    @input="errors.password = []"
                                    counter
                                    hint="Cel puțin 6 caractere"
                                    label="Confirmă Parola"
                                    prepend-icon="mdi-lock"
                                    v-model="newUser.password_confirmation"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-text>
                    <p>Permisiunile Utilizatorului</p>
                    <v-switch
                        :value="permission"
                        color="primary"
                        :label="permission"
                        v-for="permission in permissions"
                        :key="permission"
                        v-model="newUser.permissions"
                    ></v-switch>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="resetForms">Închide</v-btn>
                    <v-btn @click="saveOrUpdateUser" color="primary"
                        >Salvează</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import hasPermission from "../mixins/hasPermission";

export default {
    name: "Users",
    metaInfo: {
        title: "Utilizatori -"
    },
    mixins: [hasPermission],
    data() {
        return {
            data: {},
            createDialogTitle: "Creează User Nou",
            loading: false,
            newUser: {
                permissions: []
            },
            showPassword: false,
            showPasswordConfirm: false,
            editMode: false,
            editPassword: false,
            errors: {},
            userCreateDialog: false,
            pagination: {},
            permissions: [
                "Admin",
                "Statutul Comunei Florești",
                "Regulamente privind Procedurile Administrative",
                "Hotărârile Consiliului Local",
                "Dispozițiile Primarului",
                "Documente și Informații Financiare",
                "Alte Documente"
            ],
            headers: [
                { text: "Nume", align: "left", sortable: false, value: "name" },
                {
                    text: "Email",
                    align: "left",
                    sortable: false,
                    value: "email"
                },
                {
                    text: "Acțiuni",
                    align: "right",
                    sortable: false,
                    value: "actions"
                }
            ]
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            let response = await this.axios.get("/users", {
                params: this.data.pagination
            });
            this.loading = false;
            this.data = response.data;
        },
        resetForms() {
            this.userCreateDialog = false;
            this.newUser = {
                permissions: []
            };
            this.createDialogTitle = "Creează User Nou";
            this.editMode = false;
            this.editPassword = false;
            this.errors = [];
        },
        async saveUser() {
            try {
                const { data } = await this.axios.post("/users", this.newUser);
                this.$eventBus.$emit("notify", data.message);
                this.data.items.splice(0, 0, data.user);
                this.resetForms();
            } catch ({ response }) {
                this.$eventBus.$emit("notify", response.data.message, "error");
                this.errors = response.data.errors;
            }
        },
        async deleteUser(item) {
            const res = await this.$confirm({
                message: "Sunteți sigur(ă) că doriți să ștergeți utilizatorul?"
            });
            if (res) {
                try {
                    const response = await this.axios.post(
                        "/users/" + item.id,
                        {
                            _method: "DELETE"
                        }
                    );
                    this.$eventBus.$emit("notify", response.data.message);
                    const index = this.data.items.indexOf(item);
                    this.data.items.splice(index, 1);
                } catch ({ response }) {
                    this.$eventBus.$emit(
                        "notify",
                        response.data.message,
                        "error"
                    );
                }
            }
        },
        async updateUser() {
            try {
                const { data } = await this.axios.post(
                    "/users/" + this.newUser.id,
                    {
                        _method: "PATCH",
                        ...this.newUser
                    }
                );
                this.$eventBus.$emit("notify", data.message);
                this.resetForms();
                this.data.items.splice(this.newUser.index, 1, data.user);
            } catch ({ response }) {
                this.$eventBus.$emit("notify", response.data.message, "error");
                this.errors = response.data.errors;
            }
        },
        async updatePassword() {
            try {
                const { data } = await this.axios.post(
                    "/users/password/" + this.newUser.id,
                    { _method: "PATCH", ...this.newUser }
                );
                this.$eventBus.$emit("notify", data.message);
                this.resetForms();
                this.users.splice(this.newUser.index, 1, data.user);
            } catch ({ response }) {
                this.$eventBus.$emit("notify", response.data.message, "error");
                this.errors = response.data.errors;
            }
        },
        prepareForUpdate(item) {
            this.createDialogTitle = "Editează Userul" + ` „${item.name}”`;
            this.newUser = JSON.parse(JSON.stringify(item));
            this.newUser.index = this.data.items.indexOf(item);
            if (!this.newUser.permissions) {
                this.newUser.permissions = [];
            }
            this.editMode = true;
            this.userCreateDialog = true;
        },
        prepareForUpdatePassword(item) {
            this.createDialogTitle =
                "Editează Parola Utilizatorului" + ` „${item.name}”`;
            this.newUser = JSON.parse(JSON.stringify(item));
            this.newUser.index = this.data.items.indexOf(item);
            this.editPassword = true;
            this.userCreateDialog = true;
        },
        saveOrUpdateUser() {
            if (this.editMode) {
                this.updateUser();
                return;
            }
            if (this.editPassword) {
                this.updatePassword();
                return;
            }
            this.saveUser();
        }
    }
};
</script>
